import React, { useState } from "react";
import {
  HamburgerMenu,
  HamburgerSubMenu,
  HamburgerLink,
  Nav,
  NavLogo,
  NavLink,
  NavMenu,
} from "./Navbar/NavbarElements";

const Navbar = () => {
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  const onHamburgerMenuClick = () => {
    // console.log("onHamburgerMenuClick");

    setHamburgerMenuOpen((current) => !current);
  };

  return (
    <Nav>
      <NavLogo to="/" onClick={onHamburgerMenuClick}>
        GigaDude.com
      </NavLogo>
      <HamburgerMenu onClick={onHamburgerMenuClick} />
      {hamburgerMenuOpen === true && (
        // <ul>
        //   <li onClick={onHamburgerMenuClick}>
        //     <NavLink to="/" activeStyle>
        //       Home
        //     </NavLink>
        //   </li>
        // </ul>
        <HamburgerSubMenu>
          <HamburgerLink to="/" activeStyle onClick={onHamburgerMenuClick}>
            Home
          </HamburgerLink>
          <HamburgerLink to="/about" activeStyle onClick={onHamburgerMenuClick}>
            About
          </HamburgerLink>
          <HamburgerLink
            to="/contact"
            activeStyle
            onClick={onHamburgerMenuClick}
          >
            Contact
          </HamburgerLink>
          <HamburgerLink
            to="/portfolio"
            activeStyle
            onClick={onHamburgerMenuClick}
          >
            Portfolio
          </HamburgerLink>
        </HamburgerSubMenu>
      )}
      <NavMenu>
        <NavLink to="/" activeStyle>
          Home
        </NavLink>
        <NavLink to="/about" activeStyle>
          About
        </NavLink>
        <NavLink to="/contact" activeStyle>
          Contact
        </NavLink>
        <NavLink to="/portfolio" activeStyle>
          Portfolio
        </NavLink>
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
