import React from "react";

const Portfolio = () => {
  return (
    <div className="App">
      <h1>Portfolio</h1>
      <h2>Portfolio coming soon!</h2>
    </div>
  );
};

export default Portfolio;
