import "./App.css";

import React, { useState } from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages";
import About from "./pages/about";
import Portfolio from "./pages/portfolio";
import Contact from "./pages/contact";
import NoPage from "./pages/nopage";

// TODO: figure out to use header and footer here instead of individual pages - got here
// TODO: wire up redux - got here
// TODO: wire up router
// TODO: wire up navbar
// TODO: setup traefik for https - got here
// TODO: setup react components & router - got here
// TODO: make navbar and app responsive - got here
// TODO: move header & footer into custom components
// TODO: create ci/cd bash scripts for manual deployments
// TODO: document build process
// TODO: create ci/cd pipeline for automatic deployments
// TODO: replace javascript with typescript
// TODO: figure out how to share state across components - got here

import logo from "./images/logo.svg";

const appVersion = require("../package.json").version;
const reactVersion = React.version;

function App() {
  return (
    <Router>
      <Navbar />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </header>
      <footer className="App-footer">
        <center>
          App Version: {appVersion} - React Version: {reactVersion} - Copyright
          &copy; 2024 <a href="http://www.gigadude.com">www.gigadude.com</a> All
          rights reserved.
        </center>
      </footer>
      {/* <code>
        DEBUG CONSOLE:
        <br /><br/>
        HamburgerMenu Open: {hamburgerMenuOpen ? "yes" : "no"}<br/>
      </code> */}
    </Router>
  );
}

export default App;
