import React from "react";

// NOTE: high-res image is 15.6MB, low-res is 2.9MB
// import mangrum_family_hi_res from "../images/mangrum_family_hi_res.jpg";
import mangrum_family_low_res from "../images/mangrum_family_low_res.jpg";

const About = () => {
  return (
    <div className="App">
      <h1>About</h1>
      <h2>My Family</h2>
      {/*
        <img
          src={mangrum_family_high_res}
          className="Main-image"
          alt="Mangrum Family"
          width="50%"
          height="50%"
        /> */}
      <img
        src={mangrum_family_low_res}
        className="Main-image"
        alt="Mangrum Family"
        width="50%"
        height="50%"
      />
      <p>
        My beautiful wife Alison, myself, and our 3 dogs: 15 year old Toby the
        Maltese, 6 year old Charlie the Goldendoodle, and 9 month old Winston
        the Pembroke Welsh Corgi.
      </p>
      <h2>My Story</h2>
      <p>
        The gigadude was born in the wee hours of a dark and stormy evening in
        the town of Akron, Ohio...
      </p>
    </div>
  );
};

export default About;
