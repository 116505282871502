import React from "react";

const Home = () => {
  return (
    <div className="App">
      <h1>Welcome to GigaDude.com</h1>
      <h2>
        The personal website of the one and only gigadude: Dalton B. Mangrum.
      </h2>
    </div>
  );
};

export default Home;
