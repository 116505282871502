import React from "react";

const NoPage = () => {
  return (
    <div className="App">
      <h1>404 - Page Not Found!</h1>
    </div>
  );
};

export default NoPage;
